import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-file-drag-drop',
  templateUrl: './file-drag-drop.component.html',
  styleUrls: ['./file-drag-drop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDragDropComponent implements OnInit, OnDestroy {
  destroyed: Subject<void> = new Subject();
  static nextId = 0;
  id: number;
  @Input() accept?: string;
  @Input() useInputFile: boolean = true;
  // @Output() drop: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();
  @Output() labelClick = new EventEmitter<void>();
  @Input() handset: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver
  ) {
    this.id = ++FileDragDropComponent.nextId;
  }

  ngOnInit(): void {
    this.breakpointObserver.observe(Breakpoints.Handset).pipe(takeUntil(this.destroyed))
    .subscribe(result => {
      this.handset = result.matches;
    });
  }

  onFileChange(e: Event): void {
    const inp = e.target as HTMLInputElement;
    if (!inp.files) return;
    this.fileChange.emit(inp.files[0]);
    inp.value = "";
  }

  onLabelClick() {
    this.labelClick.emit();
  }

  @HostListener('dragover', ['$event'])
  onDrag(e: Event): void {
    e.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    if (!(e.dataTransfer && e.dataTransfer.items)) return;
    this.fileChange.emit(e.dataTransfer.items[0].getAsFile()!);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }


}
