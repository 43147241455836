import { PERMISSION, SECTION } from './permission.enum';
export const ROUTE_PERMISSIONS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],
  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  NSSF_USER: [
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_REQUEST: [
    PERMISSION.READ_HOS_REQ,
    PERMISSION.ALLOW_REGISTER_HOS_REQ,
    PERMISSION.ALLOW_APPROVE_HOS_REQ,
    PERMISSION.ALLOW_RETURN_HOS_REQ,
    PERMISSION.ALLOW_REJECT_HOS_REQ ,
    PERMISSION.ALLOW_EDIT_HOS_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL
  // -----------------------------------------------------
  HOSPITAL: [
    PERMISSION.READ_HOSPITAL,
    PERMISSION.ALLOW_EDIT_HOSPITAL,

  ],

  // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_CONTRACT: [
    PERMISSION.READ_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_CREATE_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_APPROVE_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_RETURN_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_REJECT_HOSPITAL_CONTRACT ,

  ],


  // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  PHARMACY_REQUEST: [
    PERMISSION.READ_PHARMACY_REQ,
    PERMISSION.ALLOW_REGISTER_PHARMACY_REQ,
    PERMISSION.ALLOW_APPROVE_PHARMACY_REQ,
    PERMISSION.ALLOW_RETURN_PHARMACY_REQ,
    PERMISSION.ALLOW_REJECT_PHARMACY_REQ ,
    PERMISSION.ALLOW_EDIT_PHARMACY_REQ,
  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL
  // -----------------------------------------------------
  PHARMACY: [
    PERMISSION.READ_PHARMACY,
    PERMISSION.ALLOW_EDIT_PHARMACY,
  ],

  // -----------------------------------------------------
  // MAIN: PHARMACY CONTRACT
  // -----------------------------------------------------
  PHARMACY_CONTRACT: [
    PERMISSION.READ_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_CREATE_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_APPROVE_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_RETURN_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_REJECT_PHARMACY_CONTRACT,

  ],
 
  // -----------------------------------------------------
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    SECTION.SETTING,
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],

}

export const EXTENDED_ROUTE_PMS = {
  // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  DASHBOARD: [PERMISSION.READ_DASHBOARD],
  
   // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_REQUEST: [
    PERMISSION.READ_HOS_REQ,
    PERMISSION.ALLOW_REGISTER_HOS_REQ,
    PERMISSION.ALLOW_APPROVE_HOS_REQ,
    PERMISSION.ALLOW_RETURN_HOS_REQ,
    PERMISSION.ALLOW_REJECT_HOS_REQ ,
    PERMISSION.ALLOW_EDIT_HOS_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: HOSPITAL
  // -----------------------------------------------------
  HOSPITAL: [
    PERMISSION.READ_HOSPITAL,
    PERMISSION.ALLOW_EDIT_HOSPITAL,

  ],

  // -----------------------------------------------------
  // MAIN: HOSPITAL REQUEST
  // -----------------------------------------------------
  HOSPITAL_CONTRACT: [
    PERMISSION.READ_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_CREATE_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_APPROVE_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_RETURN_HOSPITAL_CONTRACT,
    PERMISSION.ALLOW_REJECT_HOSPITAL_CONTRACT ,

  ],

  // -----------------------------------------------------
  // MAIN: PHARMACY REQUEST
  // -----------------------------------------------------
  PHARMACY_REQUEST: [
    PERMISSION.READ_PHARMACY_REQ,
    PERMISSION.ALLOW_REGISTER_PHARMACY_REQ,
    PERMISSION.ALLOW_APPROVE_PHARMACY_REQ,
    PERMISSION.ALLOW_RETURN_PHARMACY_REQ,
    PERMISSION.ALLOW_REJECT_PHARMACY_REQ ,
    PERMISSION.ALLOW_EDIT_PHARMACY_REQ,

  ],
  // -----------------------------------------------------
  // MAIN: PHARMACY
  // -----------------------------------------------------
  PHARMACY: [
    PERMISSION.READ_PHARMACY,
    PERMISSION.ALLOW_EDIT_PHARMACY,
  ],

  // -----------------------------------------------------
  // MAIN: PHARMACY
  // -----------------------------------------------------
  PHARMACY_CONTRACT: [
    PERMISSION.READ_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_CREATE_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_APPROVE_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_RETURN_PHARMACY_CONTRACT,
    PERMISSION.ALLOW_REJECT_PHARMACY_CONTRACT,

  ],

  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  NSSF_USER: [
    // SECTION.NSSF_USER,
    PERMISSION.READ_NSSF_USER,
    PERMISSION.ALLOW_CREATE_NSSF_USER,
    PERMISSION.ALLOW_EDIT_NSSF_USER,
    PERMISSION.ALLOW_DISABLE_NSSF_USER,
  ],
  
  // MAIN: SETTING
  // -----------------------------------------------------
  SETTING: [
    PERMISSION.READ_SETTING,
    PERMISSION.ALLOW_CREATE_SETTING,
    PERMISSION.ALLOW_EDIT_SETTING,
    PERMISSION.ALLOW_DISABLE_SETTING,
  ],
}
export const ROUTE_PMS_JSON = [
  {
    name: SECTION.DASHBOARD,
    key: 'DASHBOARD',
    icon: 'dashboard',
    children: [
      {
        name: SECTION.DASHBOARD_WORK,
        path: '/dashboard',
        pms_keys: [PERMISSION.READ_DASHBOARD],
      },
    ],
  },
  {
    name: SECTION.HOSPITAL,
    key: 'HOSPITAL_REQUEST',
    icon: 'local_hospital',
    children: [
      {
        name: SECTION.HOSPITAL_REQUEST,
        path: '/hospital/hospital-request',
        pms_keys: EXTENDED_ROUTE_PMS.HOSPITAL_REQUEST,
      },
      {
        name: SECTION.HOSPITAL_RESULT,
        path: '/hospital/hospital-result',
        pms_keys: EXTENDED_ROUTE_PMS.HOSPITAL,
      },
      {
        name: SECTION.HOSPITAL_CONTRACT,
        path: '/hospital/hospital-contract',
        pms_keys: EXTENDED_ROUTE_PMS.HOSPITAL_CONTRACT,
      },
    ],
  },
  {
    name: SECTION.PHARMACY,
    key: 'PHARMACY_REQUEST',
    icon: 'medication',
    children: [
      {
        name: SECTION.PHARMACY_REQUEST,
        path: '/pharmacy/pharmacy-request',
        pms_keys: EXTENDED_ROUTE_PMS.PHARMACY_REQUEST,
      },
      {
        name: SECTION.PHARMACY_RESULT,
        path: '/pharmacy/pharmacy-result',
        pms_keys: EXTENDED_ROUTE_PMS.PHARMACY,
      },
      {
        name: SECTION.PHARMACY_CONTRACT,
        path: '/pharmacy/pharmacy-contract',
        pms_keys: EXTENDED_ROUTE_PMS.PHARMACY_CONTRACT,
      },
    ],
  },
  {
    name: SECTION.NSSF_USER,
    key: 'NSSF_USER',
    icon: 'manage_accounts',
    children: [
      {
        name: SECTION.NSSF_USER,
        path: '/user-admin',
        pms_keys: EXTENDED_ROUTE_PMS.NSSF_USER,
      },
    ],
  },
  {
    name: SECTION.SETTING,
    key: 'SETTING',
    icon: 'settings',
    children: [
      {
        name: SECTION.SETTING,
        path: '/organization',
        pms_keys: EXTENDED_ROUTE_PMS.SETTING,
      },
    ],
  },
]


