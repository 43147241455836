import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/services/auth.service';
import { ScrollStatus } from 'smooth-scrollbar/interfaces';
import { RequestService } from 'src/app/services/request.service';
import { User } from 'src/app/models/user';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LANG } from 'src/app/models/enums/lang.enum';
import SmoothScrollbar from 'smooth-scrollbar';
import { HeaderTitle } from 'src/app/models/header-title';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { SingleSignOnService } from 'src/app/services/single-sign-on.service';
import { environment } from 'src/environments/environment';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { EXTENDED_ROUTE_PMS } from 'src/app/models/enums/route-permission';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeAnimation],
})
export class NavigationComponent implements OnInit, OnDestroy {
  isTablet!: boolean;
  isHandset!: boolean;

  NSSF_AUTH_URL: string = environment.NSSF_AUTH_URL;
  IS_ENABLE_OAUTH: boolean = environment.IS_ENABLE_OAUTH;
  APP_URL: string = environment.app_url;
  version: string = environment.VERSION;

  isContentScrolled!: boolean;
  scrollToTop: boolean = false;

  displayMenuItems: MenuItem[] = [];

  isScrolled!: boolean;
  isMenuIcon: boolean = false;
  menuState: 'open' | 'close' = 'close';
  user!: User;
  lang: string = '';
  permissions: string[] = [];
  title: HeaderTitle | null = null;
  showActionButton: boolean = false;
  readonly LANG = LANG;

  private _destroyed = new Subject<void>();

  constructor(
    private singleSignOnService: SingleSignOnService,
    private breakpointObserver: BreakpointObserver,
    private translate: TranslateService,
    public util: UtilService,
    private authService: AuthService,
    private requestService: RequestService,
    private localStorageService: LocalStorageService,
    private _cd: ChangeDetectorRef,
    private _router: Router
  ) {
    this.lang = this.translate.currentLang as LANG;

    let value: any = this.localStorageService.decryptSpecialCharacter(
      LocalStorageEnum.user
    );
    this.user = JSON.parse(value ? value : '');

    this.util.showActionButton
      .pipe(takeUntil(this._destroyed))
      .subscribe((res) => {
        this.showActionButton = !!res;
      });

    this.permissions = localStorageService.getArray(
      LocalStorageEnum.permissions
    );

    // Auto logout
    if (this.IS_ENABLE_OAUTH && !this.singleSignOnService.isLoggedIn) {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  private _updateActive(root: string) {
    const active = this.displayMenuItems.find((menu) => menu.root === root);
    if (Boolean(active)) {
      if (!active!.expanded) {
        active!.expanded = true;
        this._cd.detectChanges();
      }
    }
  }

  ngOnInit(): void {
    this.getMenuItems();
    this._updateActive(this._router.url.slice(1).split('/').shift()!);
    this._router.events.pipe(takeUntil(this._destroyed)).subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        const root = ev.url.slice(1).split('/').shift()!;
        this._updateActive(root);
      }
    });

    this.util.headerTitle.subscribe((title) => {
      this.title = title;
    });

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isHandset = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.breakpointObserver
      .observe(Breakpoints.TabletPortrait)
      .pipe(takeUntil(this._destroyed))
      .subscribe((response) => {
        this.isTablet = response.matches;
        this.isMenuIcon = response.matches;
      });

    this.translate.onLangChange
      .pipe(takeUntil(this._destroyed))
      .subscribe((event: LangChangeEvent) => {
        this.lang = event.lang as LANG;
      });
  }

  private traverseMenuItems(menuItems: MenuItem[]) {
    return menuItems.filter((item) => {
      if (item.children) {
        item.children = this.traverseMenuItems(item.children);
      }

      // if (!item.roles || !this.user?.role?.code) {
      //   return true;
      // }

      // return item.roles.includes(this.user.role.code);
      return true; //show all menus
    });
  }

  getMenuItems() {
    // Template for checking permission (not available yet)
    // ------
    // isNoPermission: [...EXTENDED_ROUTE_PMS.REQUEST_FORM, ...EXTENDED_ROUTE_PMS.REQUEST_LIST].filter(p => this.permissions?.includes(p)).length === 0,

		const menuItems = [
      {
        title: 'menu.dashboard',
        routerLink: '/dashboard',
        icon: 'dashboard',
        isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>this.permissions?.includes(p)).length === 0,
        roles: [],
      },
			{
				title: "menu.medical_hospital",
				routerLink: '/medical-hospital',
				icon: 'medical_services',
        // isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>this.permissions?.includes(p)).length === 0,
				roles: []
			},
      {
        title: "setting.hospital.hospital",
        icon: 'local_hospital',
        root: 'hospital',
        isNoPermission: [
          ...EXTENDED_ROUTE_PMS.HOSPITAL, 
          ...EXTENDED_ROUTE_PMS.HOSPITAL_REQUEST,
          ...EXTENDED_ROUTE_PMS.HOSPITAL_CONTRACT,

        ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'setting.hospital.hospital_request',
            routerLink: '/hospital/hospital-request',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.HOSPITAL_REQUEST.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'setting.hospital.hospital_result',
            routerLink: '/hospital/hospital-result',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.HOSPITAL.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._hospital.hos_contract',
            routerLink: '/hospital/hospital-contract',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.HOSPITAL_CONTRACT.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._hospital.hos_noncontract',
            routerLink: '/hospital/hospital-noncontract',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.HOSPITAL_CONTRACT.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          }
        ]
      },
      {
        title: "setting.pharmacy.title",
        icon: 'medication',
        root: 'pharmacy',
        isNoPermission: [
          ...EXTENDED_ROUTE_PMS.PHARMACY,
          ...EXTENDED_ROUTE_PMS.PHARMACY_REQUEST,
          ...EXTENDED_ROUTE_PMS.PHARMACY_CONTRACT

        ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: 'setting.hospital.hospital_request',
            routerLink: '/pharmacy/pharmacy-request',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY_REQUEST.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'setting.pharmacy.result',
            routerLink: '/pharmacy/pharmacy-result',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._pharmacy.phar_contract',
            routerLink: '/pharmacy/pharmacy-contract',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY_CONTRACT.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          }
        ]
      },
      {
        title: "menu._claim_request.title",
        icon: 'medication',
        root: 'claim-request',
        isNoPermission: [
          ...EXTENDED_ROUTE_PMS.PHARMACY,
          ...EXTENDED_ROUTE_PMS.PHARMACY_REQUEST,
          ...EXTENDED_ROUTE_PMS.PHARMACY_CONTRACT

        ].filter((p) => this.permissions?.includes(p)).length === 0,
        roles: [],
        children: [
          {
            title: "menu.consumption.title",
            routerLink: '/claim-request/consumption',
            icon: 'playlist_add_check_circle',
            // isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._claim_request.request',
            routerLink: '/claim-request/claim-request',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY_REQUEST.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._claim_request.to_doc',
            routerLink: '/claim-request/request-allowcate',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          },
          {
            title: 'menu._claim_request.req_note',
            routerLink: '/claim-request/request-note',
            icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.PHARMACY_CONTRACT.filter(p => this.permissions?.includes(p)).length === 0,
            roles: []
          }
        ]
      },
      // {
			// 	title: "menu.hospital_payment",
			// 	routerLink: '/hospital-payment',
			// 	icon: 'difference',
      //   // isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>this.permissions?.includes(p)).length === 0,
			// 	roles: []
			// },
      // {
			// 	title: "menu.claim_non_hf.title",
			// 	routerLink: '/claim-non-contract-hf',
			// 	icon: 'request_quote',
      //   // isNoPermission: EXTENDED_ROUTE_PMS.DASHBOARD.filter((p) =>this.permissions?.includes(p)).length === 0,
			// 	roles: []
			// },
      {
				title: "menu.user_admin",
				routerLink: '/user-admin',
				icon: 'manage_accounts',
        isNoPermission: EXTENDED_ROUTE_PMS.NSSF_USER.filter((p) => this.permissions?.includes(p) ).length === 0,
				roles: []
			},
			{
				title: "menu.setting",
				icon: 'settings',
        isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
        root: 'setting',
				roles: [],
				expanded: false,
				children: [

					{
						title: "menu.role",
						routerLink: 'setting/role',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						icon: 'work',
						roles: []
					},
					{
						title: "setting.medicine.info",
						routerLink: 'setting/medicine-info',
						icon: 'vaccines',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: []
					},
					{
						title: "setting.medicine.group",
						routerLink: 'setting/medicine-group',
						icon: 'medication',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: []
					},
					// {
					// 	title: "setting.disability_type",
					// 	routerLink: 'setting/disablity-type',
					// 	icon: 'accessible',
					// 	roles: [],
					// },
					{
						title: "setting.medical_hospital_reason",
						routerLink: 'setting/medical-hospital-reason',
						icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: [],
					}
					,
					{
						title: "setting.hospital_level",
						routerLink: "setting/hospital-level",
						icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: [],
					}
					,
					{
						title: "setting.hospital_type",
						routerLink: 'setting/hospital-type',
						icon: 'local_hospital',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: [],
					}
					,
					{
						title: "setting.hospital_package_level",
						routerLink: 'setting/hospital-package-level',
						icon: 'medical_services',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
						roles: [],
					},
          {
            title: 'setting.coverage_type',
            routerLink: 'setting/coverage-type',
            icon: 'local_shipping',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.covered_distance',
            routerLink: 'setting/covered-distance',
            icon: 'pin_drop',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.transport_sevice',
            routerLink: 'setting/transport-service',
            icon: 'currency_exchange',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.lumpsum_service',
            routerLink: 'setting/lumpsum-service',
            icon: 'paid',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.non_contract_casebase',
            routerLink: 'setting/non-contract-casebase',
            icon: 'assured_workload',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
					// ,
					// {
					// 	title: "setting.relationship",
					// 	routerLink: 'setting/relationship',
					// 	icon: 'supervisor_account',
					// 	roles: [],
					// }
					
          {
            title: 'setting.tu_hospital_wards',
            routerLink: 'setting/hospital-wards',
            icon: 'hotel',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.category_visit',
            routerLink: 'setting/category-visit',
            icon: 'checklist_rtl',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.service',
            routerLink: 'setting/service',
            icon: 'content_paste_search',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.hos_contract',
            routerLink: 'setting/hospital-contract',
            icon: 'published_with_changes',
            isNoPermission:
            EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p)).length === 0,
            roles: [],
          },
          {
            title: 'setting.tu_non_contract_hospital_services',
            routerLink: 'setting/non-contract-hospital-services',
            icon: 'sync_problem',
            isNoPermission:
            EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p)).length === 0,
            roles: [],
          },
          {
            title: 'setting.organization_category',
            routerLink: 'setting/organization-category',
            icon: 'checklist_rtl',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.organization',
            routerLink: 'setting/organization',
            icon: 'checklist_rtl',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          },
          {
            title: 'setting.hospital_payment_type',
            routerLink: 'setting/hospital-payment-type',
            icon: 'checklist_rtl',
            isNoPermission: EXTENDED_ROUTE_PMS.SETTING.filter((p) => this.permissions?.includes(p) ).length === 0,
            roles: [],
          }

				],
			},
      {
				title: 'menu._report.title',
				icon: 'report',
				roles: [],
				root: "report",
				// isNoPermission: EXTENDED_ROUTE_PMS.ALL_REPORT.filter(p => this.permissions?.includes(p)).length === 0,
				children: [
					{
						title: 'menu._report.hf_data_consumption',
						routerLink: '/report/hf-data-consumption',
						icon: 'list_alt_add',
						// isNoPermission: EXTENDED_ROUTE_PMS.REPORT_REQ_SUM.filter(p => this.permissions?.includes(p)).length === 0,
					},
					{
						title: 'menu._report.summary_hf_data_consumption',
						routerLink: '/report/summary-hf-data-consumption',
						icon: 'list_alt_add',
						// isNoPermission: EXTENDED_ROUTE_PMS.REPORT_REQ_SUM_WI.filter(p => this.permissions?.includes(p)).length === 0,
					},
					
				]
			},
		];
    this.displayMenuItems = this.traverseMenuItems(menuItems);
  }

  onToggleMenu(): void {
    this.isMenuIcon = !this.isMenuIcon;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  onCloseMenu(): void {
    this.isMenuIcon = true;
    this.util.menuChange.emit(this.isMenuIcon);
  }

  toggleExpendMenu(menu: MenuItem): void {
    menu.expanded = !menu.expanded;
  }

  onMenuScroll(e: ScrollStatus): void {
    let scrolled = e.offset.y > 0;
    if (this.isScrolled != scrolled) {
      this.isScrolled = scrolled;
      this._cd.detectChanges();
    }
  }

  onClickMenu() {
    if (!this.isMenuIcon) {
      this.isMenuIcon = this.isHandset || this.isTablet;
    }
  }

  onLogout() {
    if (this.IS_ENABLE_OAUTH && this.singleSignOnService.isLoggedIn) {
      this.singleSignOnService.onLogout();
    } else {
      this.authService.logout().subscribe((res: any) => {
        if (res.status === 1) {
          this.authService.logOutEventEmitter.emit(true);
          this.requestService.onSignOut();
        }
      });
    }
  }

  onScroll(status: ScrollStatus): void {
    let scrolled = status.offset.y > 200;
    if (this.isContentScrolled != scrolled) {
      this.isContentScrolled = scrolled;
      this._cd.detectChanges();
    }

    this.util.navigationScroll.emit(status);
  }

  onScrollLoaded(scroll: SmoothScrollbar): void {
    this.util.navigationScrollRef = scroll;
  }

  moveTop() {
    this.util.navigationScrollRef.scrollTo(0, 0, 350);
    this.util.navigationScrollRef.setMomentum(0, 1);
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}

export interface MenuItem {
  title: string; // Title to be displayed
  exact?: boolean; // Highlight when active or not
  routerLink?: string; // Link to redirect when click
  children?: MenuItem[]; // Children of the menu
  roles?: string[]; // Allowed roles for the menu
  icon?: string; // Icon to be displayed
  root?: string;
  expanded?: boolean; // Show children or not
  isNoPermission?: boolean;
}
