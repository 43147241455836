import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { RESPONSE_STATUS } from 'src/app/models/enums/response-status.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMessageComponent } from 'src/app/components/shares/snackbar-message/snackbar-message.component';
import { RequestService } from 'src/app/services/request.service';
import { SingleSignOnService } from 'src/app/services/single-sign-on.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  	hide = true;
	loginForm: UntypedFormGroup;
	NSSF_AUTH_URL: string = environment.NSSF_AUTH_URL;
	IS_ENABLE_OAUTH: boolean = environment.IS_ENABLE_OAUTH;
	NSSF_ADMIN_AUTH_CLIENT_ID: string = environment.NSSF_ADMIN_AUTH_CLIENT_ID;
	IS_SYSTEM_TESTING: boolean = environment.IS_SYSTEM_TESTING;
	version = environment.VERSION;

	constructor(private authService: AuthService,
		private snackbar: MatSnackBar,
		private singleSignOnService: SingleSignOnService,
		private requestService: RequestService,
		private _fb: UntypedFormBuilder) {

		this.loginForm = this._fb.group({
			username: [null, Validators.required],
			password: [null, Validators.required]
		});
	}

	ngOnInit(): void {
		if (this.IS_ENABLE_OAUTH) {
			this.requestService.onCleanAuthInfo();
		}
	}

	onSubmit() {
		if (this.loginForm.invalid) {
			for (let controlName in this.loginForm.controls) {
				if (this.loginForm.controls[controlName].invalid) {
					return this._openErrorSnackbar("form.errors.required." + controlName)
				}
			}
		}

		this.authService.login(this.loginForm.value).subscribe({
			next: (res) => {
				switch (res.status) {
					case RESPONSE_STATUS.SUCCESS:
						this.authService.logInEventEmitter.emit(true);
						return;
					case RESPONSE_STATUS.ERROR_CLIENT:
						switch (res.status) {
							case 0:
								this._openErrorSnackbar("form.errors.login.incorrect");
								break;
							default:
								this._openErrorSnackbar("general.failed");
						}
						break;
					case RESPONSE_STATUS.UNKNOWN:
						this.authService.onOldUserLogin();
						break;
				}
			}
		});
	};

	/**
	 *
	 * @param msg localize string
	 */
	private _openErrorSnackbar(msg: string, params?: object): void {
		this.snackbar.openFromComponent(SnackbarMessageComponent, {
			data: {
				message: msg,
				params
			},
			duration: 1500,
			panelClass: "panel-error"
		});
	}

	onLogin() {
		this.singleSignOnService.onLogin();
	}
}
