export enum PERMISSION {
   // -----------------------------------------------------
  // MAIN: DASHBOARD
  // -----------------------------------------------------
  READ_DASHBOARD = 'READ_DASHBOARD',

  // MAIN: HOSTPITAL
  // -----------------------------------------------------
  READ_HOSPITAL = "READ_HOSPITAL",
  ALLOW_EDIT_HOSPITAL = "ALLOW_EDIT_HOSPITAL",

  // MAIN: HOSTPITAL REQUEST
  // -----------------------------------------------------
  READ_HOS_REQ="READ_HOS_REQ",
  ALLOW_REGISTER_HOS_REQ="ALLOW_REGISTER_HOS_REQ",
  ALLOW_APPROVE_HOS_REQ = "ALLOW_APPROVE_HOS_REQ",
  ALLOW_RETURN_HOS_REQ = "ALLOW_RETURN_HOS_REQ",
  ALLOW_EDIT_HOS_REQ = "ALLOW_EDIT_HOS_REQ",
  ALLOW_REJECT_HOS_REQ="ALLOW_REJECT_HOS_REQ",

  // MAIN: HOSTPITAL CONTRACT
  // -----------------------------------------------------
  READ_HOSPITAL_CONTRACT="READ_HOSPITAL_CONTRACT",
  ALLOW_CREATE_HOSPITAL_CONTRACT = "ALLOW_CREATE_HOSPITAL_CONTRACT",
  ALLOW_APPROVE_HOSPITAL_CONTRACT = "ALLOW_APPROVE_HOSPITAL_CONTRACT",
  ALLOW_RETURN_HOSPITAL_CONTRACT = "ALLOW_RETURN_HOSPITAL_CONTRACT",
  ALLOW_REJECT_HOSPITAL_CONTRACT="ALLOW_REJECT_HOSPITAL_CONTRACT",

  // MAIN: PHARMACY
  // -----------------------------------------------------
  READ_PHARMACY = "READ_PHARMACY",
  ALLOW_EDIT_PHARMACY = "ALLOW_EDIT_PHARMACY",

  // MAIN: PHARMACY REQUEST
  // -----------------------------------------------------
  READ_PHARMACY_REQ = "READ_PHARMACY_REQ",
  ALLOW_REGISTER_PHARMACY_REQ = "ALLOW_REGISTER_PHARMACY_REQ",
  ALLOW_APPROVE_PHARMACY_REQ = "ALLOW_APPROVE_PHARMACY_REQ",
  ALLOW_RETURN_PHARMACY_REQ = "ALLOW_RETURN_PHARMACY_REQ",
  ALLOW_REJECT_PHARMACY_REQ = "ALLOW_REJECT_PHARMACY_REQ",
  ALLOW_EDIT_PHARMACY_REQ = "ALLOW_EDIT_PHARMACY_REQ",

  // MAIN: PHARMACY CONTRACT
  // -----------------------------------------------------
  READ_PHARMACY_CONTRACT = "READ_PHARMACY_CONTRACT",
  ALLOW_CREATE_PHARMACY_CONTRACT = "ALLOW_CREATE_PHARMACY_CONTRACT",
  ALLOW_APPROVE_PHARMACY_CONTRACT = "ALLOW_APPROVE_PHARMACY_CONTRACT",
  ALLOW_RETURN_PHARMACY_CONTRACT = "ALLOW_RETURN_PHARMACY_CONTRACT",
  ALLOW_REJECT_PHARMACY_CONTRACT = "ALLOW_REJECT_PHARMACY_CONTRACT",

  // -----------------------------------------------------
  // MAIN: NSSF USER
  // -----------------------------------------------------
  READ_NSSF_USER = 'READ_NSSF_USER',
  ALLOW_CREATE_NSSF_USER = 'ALLOW_CREATE_NSSF_USER',
  ALLOW_EDIT_NSSF_USER = 'ALLOW_EDIT_NSSF_USER',
  ALLOW_DISABLE_NSSF_USER = 'ALLOW_DISABLE_NSSF_USER',

  // -----------------------------------------------------
  // MAIN: SETTINGS
  // -----------------------------------------------------
  READ_SETTING = 'READ_SETTING',
  ALLOW_CREATE_SETTING = 'ALLOW_CREATE_SETTING',
  ALLOW_EDIT_SETTING = 'ALLOW_EDIT_SETTING',
  ALLOW_DISABLE_SETTING = 'ALLOW_DISABLE_SETTING',
  // -----------------------------------------------------
  //  HOSPITAL CON REQUEST
  READ_HOS_CON_REQ="READ_HOS_CON_REQ",
  ALLOW_CREATE_HOS_CON_REQ ="ALLOW_CREATE_HOS_CON_REQ",
  ALLOW_APPROVE_HOS_CON_REQ="ALLOW_APPROVE_HOS_CON_REQ",
  ALLOW_RETURN_HOS_CON_REQ="ALLOW_RETURN_HOS_CON_REQ",
  ALLOW_REJECT_HOS_CON_REQ="ALLOW_REJECT_HOS_CON_REQ",
  //  HOSPITAL TRAN REQUEST
  READ_HOS_CON_TRAN_REQ="READ_HOS_CON_TRAN_REQ",
  ALLOW_CREATE_HOS_CON_TRAN_REQ="ALLOW_CREATE_HOS_CON_TRAN_REQ",
  ALLOW_APPROVE_HOS_CON_TRAN_REQ="ALLOW_APPROVE_HOS_CON_TRAN_REQ",
  ALLOW_RETURN_HOS_CON_TRAN_REQ="ALLOW_RETURN_HOS_CON_TRAN_REQ",
  ALLOW_REJECT_HOS_CON_TRAN_REQ="ALLOW_REJECT_HOS_CON_TRAN_REQ",
  //  HOSPITAL CON ASSIGN
  READ_HOS_CON_ASSIGN_REQ="READ_HOS_CON_ASSIGN_REQ",
  ALLOW_CREATE_HOS_CON_ASSIGN_REQ="ALLOW_CREATE_HOS_CON_ASSIGN_REQ",
  ALLOW_APPROVE_HOS_CON_ASSIGN_REQ="ALLOW_APPROVE_HOS_CON_ASSIGN_REQ",
  ALLOW_RETURN_HOS_CON_ASSIGN_REQ= "ALLOW_RETURN_HOS_CON_ASSIGN_REQ",
  ALLOW_REJECT_HOS_CON_ASSIGN_REQ="ALLOW_REJECT_HOS_CON_ASSIGN_REQ"
}
export enum SECTION {
    // -----------------------------------------------------
    // MAIN: DASHBOARD
    // -----------------------------------------------------
    DASHBOARD = 'menu.dashboard',
    DASHBOARD_WORK = 'menu.dashboard',

    // MAIN: VICTIM
    // -----------------------------------------------------
    VICTIM_LIST = 'menu.victim_list',

    // MAIN: HOSPITAL REQUEST
    // -----------------------------------------------------
    HOSPITAL_REQUEST = 'setting.hospital.hospital_request',
    HOSPITAL = 'setting.hospital.hospital',

    // MAIN: HOSPITAL  REQUEST RESULT
    // -----------------------------------------------------
    HOSPITAL_RESULT = 'setting.hospital.hospital_result',

    // MAIN: HOSPITAL CONTRACT
    // -----------------------------------------------------
    HOSPITAL_CONTRACT = 'menu._hospital.hos_contract',

    // MAIN: HOSPITAL CONTRACT
    // -----------------------------------------------------
    HOSPITAL_NONCONTRACT = 'menu._hospital.hos_noncontract',

    // MAIN: PHARMACY REQUEST
    // -----------------------------------------------------
    PHARMACY_REQUEST = 'setting.hospital.hospital_request',
    PHARMACY = 'setting.pharmacy.title',

    // MAIN: HOSPIT REQUEST
    // -----------------------------------------------------
    HOSPITAL_CON ='menu._claim_request.title',
    HOSPITAL_CON_REQUEST= 'menu.consumption.title',
    HOSPITAL_CON_TRAN_REQUEST= 'menu._claim_request.request',
    HOSPITAL_CON_ASSIGN_REQUEST= 'menu._claim_request.to_doc',
    // MAIN: PHARMACY  REQUEST RESULT
    // -----------------------------------------------------
    PHARMACY_RESULT = 'setting.pharmacy.result',

    // MAIN: PHARMACY CONTRACT
    // -----------------------------------------------------
    PHARMACY_CONTRACT = 'menu._pharmacy.phar_contract',

    // MAIN: NSSF USER
    // -----------------------------------------------------
    NSSF_USER = 'menu.user_admin',

    // -----------------------------------------------------
    // MAIN: SETTINGS
    // -----------------------------------------------------
    SETTING = 'menu.setting',
    

}