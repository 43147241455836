export const environment = {
  production: false,
  api_url: 'https://nssf-hspis-api.myplgs.com/admin/api/v1',
  app_url: 'https://nssf-hspis.myplgs.com',

  LIMIT_PRINT_CARD: 20,
  DEFAULT_USER_IMAGE: "/assets/images/default_profile.png",
  VERSION: "0.0.1",

  NSSF_REDIRECT_URI: 'https://nssf-hspis.myplgs.com/auth/callback',
  NSSF_AUTH_URL: 'https://accountuat.nssf.gov.kh',
  NSSF_ADMIN_AUTH_CLIENT_ID: '973b0024-5e1d-d58e-3d59-cebea3374889',

  /** OAUTH2 */
  NSSF_LOGOUT_REDIRECT_URI: 'https://nssf-hspis.myplgs.com/login',
  IS_ENABLE_OAUTH: true,
  IS_SYSTEM_TESTING: false
};

