import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './components/general/navigation/navigation.component';
// import { MemeberLayoutComponent } from './components/member/components/memeber-layout/memeber-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { CallbackComponent } from './components/callback/callback.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        data: {},
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },

      {
        path: 'user-admin',
        data: {},
        loadChildren: () => import('./components/user-admin/user-admin.module').then(m => m.UserAdminModule)
      },
    
      {
        path: 'medical-hospital',
        data: {},
        loadChildren: () => import('./components/medical-hospital/medical-hospital.module').then(m => m.MedicalHospitalModule)
      },
      {
        path: 'hospital',
        data: {},
        loadChildren: () => import('./components/hospital/hospital.module').then((m)=>m.HospitalModule)
      },
      {
        path: 'pharmacy',
        data: {},
        loadChildren: () => import('./components/pharmacy/pharmacy.module').then((m)=>m.PharmacyModule)
      },
      {
        path: 'hospital-payment',
        data: {},
        loadChildren: () => import('./components/hospital-payment/hospital-payment.module').then(m => m.HospitalPaymentModule)
      },
      {
        path: 'claim-request',
        data: {},
        loadChildren: () => import('./components/claim-request-section/claim-request-section.module').then(m => m.ClaimRequestSectionModule)
      },
      {
        path: 'claim-non-contract-hf',
        data: {},
        loadChildren: () => import('./components/claim-non-contract-hf/claim-non-contract-hf.module').then(m => m.ClaimNonContractHFModule)
      },
      {
        path: 'setting',
        data: {},
        loadChildren: () => import('./components/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'report',
        data: {},
        loadChildren: () => import('./components/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'about-us',
        data: {},
        loadChildren: () => import('./components/about-us/about-us.module').then((m) => m.AboutUsModule),
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'auth/callback',
    component: CallbackComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

